import App, { AppContext, AppProps } from "next/app";
import localFont from "next/font/local";
import * as React from "react";
import { ParallaxProvider } from "react-scroll-parallax";

import { SinglePageResult } from "@bs/api-portal";
import { IconsProvider } from "@bs/icons";

import iconsSpritePath from "@bs/icons/sprite.svg";
import { LanguageProvider } from "@bs/language";

import {
  ContactFormProvider,
  Footer,
  Header,
  PageProgress,
} from "@bs/components";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import Head from "next/head";
import "../styles/globals.css";
import "../styles/wordpress.css";

const satoshi = localFont({
  src: [
    {
      path: "../public/static/fonts/Satoshi-Regular.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "../public/static/fonts/Satoshi-Italic.woff2",
      weight: "400",
      style: "italic",
    },
    {
      path: "../public/static/fonts/Satoshi-Medium.woff2",
      weight: "500",
      style: "normal",
    },
    {
      path: "../public/static/fonts/Satoshi-MediumItalic.woff2",
      weight: "500",
      style: "italic",
    },
    {
      path: "../public/static/fonts/Satoshi-Bold.woff2",
      weight: "700",
      style: "normal",
    },
    {
      path: "../public/static/fonts/Satoshi-BoldItalic.woff2",
      weight: "700",
      style: "italic",
    },
  ],
  fallback: ["Helvetica", "Verdana", "Arial", "sans-serif"],
  variable: "--font-satoshi",
});

const poppins = localFont({
  src: [
    {
      path: "../public/static/fonts/Poppins-Bold.ttf",
      weight: "700",
      style: "normal",
    },
    {
      path: "../public/static/fonts/Poppins-BoldItalic.ttf",
      weight: "700",
      style: "italic",
    },
  ],
  fallback: ["Montserrat", "Open Sans", "Verdana"],
  variable: "--font-poppins",
});

interface Props {
  pageProps: SinglePageResult;
}
export default function MyApp({
  Component,
  pageProps,
}: Omit<AppProps, "pageProps"> & Props) {
  const { title, description, keywords, ogSettings } =
    pageProps.pageSettings || {};
  return (
    <React.Fragment>
      <style jsx global>{`
        html {
          font-family: ${satoshi.style.fontFamily};
        }
      `}</style>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
        {keywords && <meta name="keywords" content={keywords} />}
        <link rel="icon" href="/favicon.ico" sizes="any" />

        {Array.isArray(ogSettings) && (
          <>
            <meta property="og:locale" content="pl_PL" />
            {ogSettings.map(({ type, content }, index) => {
              if (!content) {
                return null;
              }
              return (
                <meta key={index} property={`og:${type}`} content={content} />
              );
            })}
          </>
        )}
      </Head>
      <div className={`${satoshi.variable} ${poppins.variable}`}>
        <GoogleReCaptchaProvider reCaptchaKey="6Lc4dcApAAAAADToUGPWUQfb1K1wTvrjv8P1zHlS">
          <LanguageProvider language={pageProps.language}>
            <IconsProvider src={iconsSpritePath}>
              <ParallaxProvider>
                <ContactFormProvider>
                  <PageProgress />
                  {pageProps.header && <Header {...pageProps.header} />}
                  <main className="shadow-sm relative">
                    <Component {...pageProps} />
                  </main>
                  {pageProps.footer && <Footer {...pageProps.footer} />}
                </ContactFormProvider>
              </ParallaxProvider>
            </IconsProvider>
          </LanguageProvider>
        </GoogleReCaptchaProvider>
      </div>
    </React.Fragment>
  );
}

MyApp.getInitialProps = async function getInitialProps(appContext: AppContext) {
  const [pageProps] = await Promise.all([App.getInitialProps(appContext)]);

  return {
    pageProps: pageProps.pageProps,
  };
};
