import * as React from "react";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { twMerge } from "tailwind-merge";

export function Scroll({
  children,
  className,
  ...rest
}: React.PropsWithChildren<React.ComponentProps<typeof SimpleBar>>) {
  return (
    <React.Fragment>
      <SimpleBar
        autoHide={false}
        className={twMerge(
          "pr-3",
          className,
          "[&_.simplebar-track_.simplebar-scrollbar:before]:bg-action",
          "[&_.simplebar-track_.simplebar-scrollbar:before]:transition-opacity",
          "[&_.simplebar-hover_.simplebar-scrollbar:before]:opacity-100",
          "[&_.simplebar-visible_.simplebar-scrollbar:before]:opacity-100"
        )}
        {...rest}
      >
        {children}
      </SimpleBar>
    </React.Fragment>
  );
}
