import BaseLink from "next/link";
import * as React from "react";

type BaseLinkProps = React.ComponentProps<typeof BaseLink>;
type LinkProps =
  | Partial<Pick<BaseLinkProps, "href">>
  | Omit<BaseLinkProps, "legacyBehavior" | "passHref">;

export function Link({
  children,
  href,
  ...rest
}: React.PropsWithChildren<LinkProps>) {
  const linkUrl = href || "#";
  if (React.isValidElement(children)) {
    if (React.Children.only(children).type === "a") {
      return (
        <BaseLink legacyBehavior={true} passHref={true} href={linkUrl}>
          {React.cloneElement(children, {
            ...rest,
          })}
        </BaseLink>
      );
    }
  }

  return (
    <BaseLink prefetch={false} {...rest} href={linkUrl}>
      {children}
    </BaseLink>
  );
}
