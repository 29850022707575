import { PortalLanguage } from "@bs/language";
import { useRouter } from "next/router";
import * as React from "react";
import { Link } from "../Link/Link";
import { LanguageSwitch } from "./LanguageSwitch";
import { HamburgerMenu } from "./Menu/HamburgerMenu";
import { MenuItem } from "./Menu/MenuItem";
import { SocialLink } from "./SocialLink";
import { HeaderData } from "./types";

export function Header({
  mainMenu,
  socials,
  showLanguageSwitch = true,
}: HeaderData) {
  const router = useRouter();

  const onLanguageSwitch = React.useCallback(
    (language: PortalLanguage) => {
      const path = router.asPath;

      return router.push(path, path, { locale: language });
    },
    [router]
  );

  return (
    <header>
      <div className="w-full h-[60px] from-tablet:h-20 bg-gradient-header from-black/10 from-10% to-white/10 to-90% pb-px">
        <div className="h-full w-full bg-primary relative z-20">
          <div className="container h-full">
            <div className="h-full flex justify-between items-center ">
              <div className="logo-2xl">
                <Link href="/">
                  Better<span className="text-active">steps.</span>
                </Link>
              </div>
              <nav className="flex gap-10 to-desktop:hidden">
                {mainMenu.map((menuItem) => {
                  return <MenuItem key={menuItem.id} {...menuItem} />;
                })}
              </nav>
              <div className="flex gap-4 items-center text-sm to-desktop:hidden">
                {socials.map((social) => {
                  return <SocialLink key={social.id} {...social} />;
                })}
                {onLanguageSwitch && showLanguageSwitch && (
                  <LanguageSwitch onLanguageSwitch={onLanguageSwitch} />
                )}
              </div>
              <div className="from-desktop:hidden">
                <HamburgerMenu
                  mainMenu={mainMenu}
                  socials={socials}
                  showLanguageSwitch={showLanguageSwitch}
                  onLanguageSwitch={onLanguageSwitch}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
