import * as React from "react";

import { useIconsProvider } from "./IconsProvider";
import type { IconType } from "./iconTypes";

type IconProps = { name: IconType } & React.SVGProps<SVGSVGElement>;

export function Icon({ name, ...rest }: IconProps) {
  const src = useIconsProvider();
  return (
    <svg {...rest} width="1em" height="1em">
      <use fill="currentColor" xlinkHref={`${src}#${name}`} />
    </svg>
  );
}
