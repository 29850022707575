import { showCookieSettings } from "../Action/helpers/cookieSettings";
import { Link } from "../Link";
import { FooterData } from "./types";

export function Footer({ footerLinks }: FooterData) {
  return (
    <footer className="w-full bg-secondary">
      <div className="container py-7">
        <div className="flex w-full justify-between">
          <div className="flex flex-col gap-5">
            {footerLinks &&
              footerLinks.map(({ id, link, title }) => {
                return (
                  <Link
                    key={id}
                    href={link?.url}
                    target={link?.target}
                    className="text-primary hover:underline"
                    onClick={(e) => {
                      if (link?.url === "#cookie.instance.management") {
                        e.preventDefault();
                        showCookieSettings();
                        return;
                      }
                    }}
                  >
                    {title}
                  </Link>
                );
              })}
          </div>
          <div className="flex flex-col gap-5 text-primary text-right">
            <p>&copy; {new Date().getFullYear()} Bettersteps.</p>
            <p>Wszystkie prawa zastrzeżone.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
