import * as React from "react";
import { disableBodyScroll, enableBodyScroll } from "./body-scroll-lock";
import { getClosest } from "./getClosest";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  enabled?: boolean;
}

export function RemoveScroll({
  enabled = true,
  ...props
}: React.PropsWithChildren<Props>) {
  const ref = React.useRef<HTMLDivElement>(null);
  const remove = React.useCallback((ref: HTMLElement) => {
    disableBodyScroll(ref, {
      allowTouchMove: (e) => {
        return getClosest(e, [
          "simplebar-content-wrapper",
          "allow-content-scroll",
        ]);
      },
      reserveScrollBarGap: true,
    });
  }, []);

  const restore = React.useCallback((ref: HTMLElement) => {
    enableBodyScroll(ref);
  }, []);

  React.useEffect(() => {
    if (!ref.current || !enabled) {
      return;
    }

    const currentRef = ref.current;
    remove(currentRef);

    return () => {
      restore(currentRef);
    };
  }, [enabled, remove, restore]);

  return <div {...props} ref={ref} />;
}
