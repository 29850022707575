import { cx } from "class-variance-authority";
import { Link } from "../../Link/Link";
import { MainMenuNode } from "../types";
import { MenuItemWithDropdown } from "./MenuItemWithDropdown";

export function MenuItem(props: MainMenuNode) {
  const { link, title, children } = props;
  const hasChildren = Array.isArray(children) && children.length > 0;

  if (hasChildren) {
    return <MenuItemWithDropdown {...props} />;
  }

  return (
    <Link
      href={link?.url}
      target={link?.target}
      className={cx(
        "text-secondary text-base border-b-2 border-transparent pb-2",
        "hover:text-active hover:border-active transition-colors"
      )}
    >
      {title}
    </Link>
  );
}
