import * as React from "react";
type ContactFormContext = {
  sectionRef: React.MutableRefObject<HTMLDivElement | null>;
  setRef: (ref: HTMLDivElement) => void;
};

const Context = React.createContext<ContactFormContext | null>(null);

export function ContactFormProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const sectionRef = React.useRef<HTMLDivElement | null>(null);

  const setRef = React.useCallback((ref: HTMLDivElement) => {
    sectionRef.current = ref;
  }, []);

  const value = React.useMemo(() => {
    return { sectionRef, setRef };
  }, [sectionRef, setRef]);
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export const useContactForm = () => {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error(
      "useContactFormProvider: Please provide ContactFormProvider value."
    );
  }
  return context;
};
