import { Icon, IconType } from "@bs/icons";
import { PortalLanguage, useLanguage } from "@bs/language";

import * as React from "react";

interface LanguageMapProps {
  title: string;
  icon: IconType;
}
const languageMap: Record<PortalLanguage, LanguageMapProps> = {
  pl: {
    title: "Zamień na język Polski",
    icon: "flag-pl",
  },
  en: {
    title: "Change to English language",
    icon: "flag-uk",
  },
};

interface Props {
  onLanguageSwitch(language: PortalLanguage): void;
}

export function LanguageSwitch({ onLanguageSwitch }: Props) {
  const language = useLanguage();

  const oppositeLanguage = React.useMemo(() => {
    return language === "pl" ? "en" : "pl";
  }, [language]);
  const { title, icon } = languageMap[oppositeLanguage];

  const switchLanguage = React.useCallback(() => {
    onLanguageSwitch(oppositeLanguage);
  }, [onLanguageSwitch, oppositeLanguage]);

  return (
    <div
      onClick={switchLanguage}
      title={title}
      className="cursor-pointer text-base"
    >
      <Icon name={icon} />
    </div>
  );
}
