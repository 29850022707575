import { Icon } from "@bs/icons";
import { RemoveScroll, Scroll } from "@bs/ui";
import { Disclosure, Transition } from "@headlessui/react";
import { cx } from "class-variance-authority";

import { PortalLanguage } from "@bs/language";
import * as React from "react";
import { Link } from "../../Link/Link";
import { LanguageSwitch } from "../LanguageSwitch";
import { SocialLink } from "../SocialLink";
import { isClickedOutside } from "../helpers/isClickedOutside";
import { MainMenuNode, SocialNode } from "../types";

interface Props {
  mainMenu: MainMenuNode[];
  socials: SocialNode[];
  showLanguageSwitch: boolean;
  onLanguageSwitch?: (language: PortalLanguage) => void;
}

export function HamburgerMenu({
  mainMenu,
  socials,
  showLanguageSwitch,
  onLanguageSwitch,
}: Props) {
  const popoverRef = React.useRef<HTMLDivElement>(null);
  const triggerRef = React.useRef<HTMLButtonElement>(null);
  const [isOpened, setMenuOpened] = React.useState(false);

  const onHamburgerClick = React.useCallback(() => {
    setMenuOpened((state) => !state);
  }, []);

  const onLinkClick = React.useCallback(() => {
    setMenuOpened(false);
  }, []);

  React.useEffect(() => {
    if (!isOpened) {
      return;
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (isClickedOutside(event, popoverRef)) {
        setMenuOpened(false);
      }
    };

    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setMenuOpened(false);
        triggerRef.current?.focus();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscape);
    };
  }, [isOpened]);

  return (
    <div ref={popoverRef}>
      <div>
        <button
          onClick={onHamburgerClick}
          ref={triggerRef}
          className="border-0 outline-0 text-secondary hover:text-active text-base"
        >
          <Icon name={isOpened ? "close" : "hamburger"} />
        </button>
      </div>
      <Transition
        show={isOpened}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={cx(
          "absolute shadow-sm shadow-secondary top-[60px] from-tablet:top-20 only-mobile:left-0 from-tablet:right-0",
          "w-screen from-tablet:w-80",
          "h-[calc(100dvh-60px)] from-tablet:h-[calc(100dvh-80px)]",
          "bg-secondary text-primary"
        )}
      >
        <RemoveScroll enabled={isOpened} className="w-full h-full">
          <div className="w-full h-[calc(100%-60px)] shadow-lg">
            <Scroll className="h-full pr-0">
              <div className="flex flex-col">
                {mainMenu.map(({ id, link, title, children }) => {
                  const hasChildren =
                    Array.isArray(children) && children.length > 0;
                  if (!hasChildren) {
                    return (
                      <div
                        key={id}
                        className="border-b border-primary last-of-type:border-transparent"
                      >
                        <Link
                          href={link?.url}
                          target={link?.target}
                          onClick={onLinkClick}
                          className="w-full p-4 block hover:text-action transition-colors"
                        >
                          {title}
                        </Link>
                      </div>
                    );
                  }

                  return (
                    <Disclosure key={id}>
                      {({ open }) => (
                        <div className="border-b border-primary last-of-type:border-transparent">
                          <Disclosure.Button
                            className={cx(
                              "w-full p-4 flex gap-1 justify-between items-center",
                              "border-transparent hover:text-action transition-colors"
                            )}
                          >
                            <span>{title}</span>
                            <div
                              className={cx(
                                "transition-transform text-sm",
                                open && "rotate-180"
                              )}
                            >
                              <Icon name="arrow-down" />
                            </div>
                          </Disclosure.Button>
                          <Disclosure.Panel className="pl-6 flex flex-col">
                            {children.map(({ id, link, title }) => {
                              return (
                                <Link
                                  onClick={onLinkClick}
                                  key={id}
                                  href={link?.url}
                                  target={link?.target}
                                  className="w-full p-4 hover:text-action transition-colors"
                                >
                                  {title}
                                </Link>
                              );
                            })}
                          </Disclosure.Panel>
                        </div>
                      )}
                    </Disclosure>
                  );
                })}
              </div>
            </Scroll>
          </div>
          <div className="h-[60px] bg-primary flex gap-8 justify-center items-center">
            {socials.map((social) => {
              return <SocialLink key={social.id} {...social} />;
            })}
            {onLanguageSwitch && showLanguageSwitch && (
              <LanguageSwitch onLanguageSwitch={onLanguageSwitch} />
            )}
          </div>
        </RemoveScroll>
      </Transition>
    </div>
  );
}
