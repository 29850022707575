export function isClickedOutside(
  event: MouseEvent,
  wrapperRef: React.RefObject<HTMLDivElement> | null
) {
  const targetPaths = event.composedPath
    ? event.composedPath()
    : // @ts-expect-error https://dom.spec.whatwg.org/#event-path
      event.path;
  const target =
    targetPaths[0] || (event.target instanceof HTMLElement && event.target);

  return (
    target &&
    wrapperRef &&
    wrapperRef.current &&
    !wrapperRef.current.contains(target)
  );
}
