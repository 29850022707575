function isWindowCookieScript(window: Window): window is Window & {
  CookieScript: {
    instance: {
      show(): void;
    };
  };
} {
  return typeof window !== "undefined" && "CookieScript" in window;
}

export function showCookieSettings() {
  if (isWindowCookieScript(window)) {
    window.CookieScript.instance.show();
  }
}
