import { Icon } from "@bs/icons";
import { Transition } from "@headlessui/react";
import { cx } from "class-variance-authority";
import { useRouter } from "next/router";
import * as React from "react";
import { Link } from "../../Link/Link";
import { isClickedOutside } from "../helpers/isClickedOutside";
import { MainMenuNode } from "../types";

export function MenuItemWithDropdown({ children, title }: MainMenuNode) {
  const popoverRef = React.useRef<HTMLDivElement>(null);
  const triggerRef = React.useRef<HTMLDivElement>(null);
  const [isOpened, setDropdownOpened] = React.useState(false);
  const router = useRouter();

  const onClick = React.useCallback(() => {
    setDropdownOpened((e) => !e);
  }, []);

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter") {
        setDropdownOpened((e) => !e);
      }
    },
    []
  );

  const onLinkClick = React.useCallback(() => {
    setDropdownOpened(false);
  }, []);

  React.useEffect(() => {
    function routeChangeStart() {
      setDropdownOpened(false);
    }

    router.events.on("routeChangeStart", routeChangeStart);

    return () => {
      router.events.off("routeChangeStart", routeChangeStart);
    };
  }, [router.events]);

  React.useEffect(() => {
    if (!isOpened) {
      return;
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (isClickedOutside(event, popoverRef)) {
        setDropdownOpened(false);
      }
    };

    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setDropdownOpened(false);
        triggerRef.current?.focus();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscape);
    };
  }, [isOpened]);

  return (
    <div
      ref={popoverRef}
      className="relative"
      onKeyDown={onKeyDown}
      onClick={onClick}
      tabIndex={0}
    >
      <div
        ref={triggerRef}
        className={cx(
          "flex gap-2 items-center cursor-pointer text-base",
          "border-b-2 pb-2",
          "hover:text-active hover:border-active transition-colors",
          isOpened
            ? "text-active border-active"
            : "text-secondary border-transparent"
        )}
      >
        <span>{title}</span>
        <div
          className={cx(
            "transition-transform text-sm",
            isOpened && "rotate-180"
          )}
        >
          <Icon name="arrow-down" />
        </div>
      </div>
      <Transition
        show={isOpened}
        enter="transition-all duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="absolute min-w-52 top-[57px] -left-4 bg-secondary text-primary"
      >
        <div className="flex flex-col w-full shadow-xl">
          {children.map(({ id, link, title }, index) => {
            return (
              <React.Fragment key={id}>
                <Link
                  href={link?.url}
                  target={link?.target}
                  onClick={onLinkClick}
                  className={cx(
                    "w-full p-4 border-2 border-transparent relative z-10",
                    "hover:border-action transition-colors"
                  )}
                >
                  <span className="whitespace-nowrap">{title}</span>
                </Link>

                {index + 1 < children.length && (
                  <div className="-mb-[1px] -mt-[1px] border-b border-primary" />
                )}
              </React.Fragment>
            );
          })}
        </div>
      </Transition>
    </div>
  );
}
