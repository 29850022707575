import * as React from "react";

const Context = React.createContext("");

type IconsProviderProps = {
  src: string;
};

export function IconsProvider({
  src,
  children,
}: React.PropsWithChildren<IconsProviderProps>) {
  return <Context.Provider value={src}>{children}</Context.Provider>;
}

export function useIconsProvider() {
  return React.useContext(Context);
}
