import * as React from "react";
import * as r from "runtypes";

const Context = React.createContext<PortalLanguage>("pl");

export const PortalLanguage = r.Union(r.Literal("pl"), r.Literal("en"));
export type PortalLanguage = r.Static<typeof PortalLanguage>;

type LanguageProviderProps = {
  language: PortalLanguage;
};

export function LanguageProvider(
  props: React.PropsWithChildren<LanguageProviderProps>
) {
  const language = React.useMemo(() => {
    return props.language;
  }, [props.language]);
  return <Context.Provider value={language}>{props.children}</Context.Provider>;
}

export function useLanguage() {
  return React.useContext(Context);
}
