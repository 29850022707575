import { Icon, IconType } from "@bs/icons";
import { SocialNode, SocialType } from "./types";

interface SocialMap {
  iconType: IconType;
  hoverColor: string;
}

const socialMaps: Record<SocialType, SocialMap> = {
  FACEBOOK: {
    iconType: "facebook",
    hoverColor: "#3b5998",
  },
  LINKEDIN: {
    iconType: "linkedin",
    hoverColor: "#0077b5",
  },
};

export function SocialLink({ title, link, type }: SocialNode) {
  if (!(type in socialMaps)) {
    return;
  }

  const { iconType, hoverColor } = socialMaps[type];

  return (
    <div
      // @ts-expect-error missing types for css vars
      style={{ "--socialHoverColor": hoverColor }}
    >
      <a
        href={link?.url}
        target={link?.target}
        title={title}
        className="text-secondary hover:text-[var(--socialHoverColor)]"
      >
        <Icon name={iconType} />
        <span className="sr-only">{title}</span>
      </a>
    </div>
  );
}
