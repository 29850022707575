export function getClosest(elem: any, classNames: string[]) {
  for (; elem && elem !== document; elem = elem.parentNode) {
    const hasClass = classNames.some((className) => {
      return (
        typeof elem.className === "string" && elem.className.includes(className)
      );
    });
    if (hasClass) {
      return elem;
    }
  }
  return null;
}
